<template>
  <v-card class="py-3" @click="$emit('action')" outlined>
    <v-row align="center">
      <v-col class="pb-1 text-center" cols="12">
        <v-avatar v-if="image" class="mx-auto" size="40" rounded color="white">
          <v-tooltip bottom>
            <template #activator="{ on, attrs }">
              <v-img
                class="mx-auto"
                contain
                height="40"
                v-on="on"
                :src="image"
              />
            </template>
            <span>
              {{ imageHover }}
            </span>
          </v-tooltip>
        </v-avatar>
        <v-avatar v-else rounded size="40" :color="iconColor || 'success'">
          <v-icon color="white" size="25" v-text="icon"></v-icon>
        </v-avatar>
        <span style="position: absolute; right: 12px; float: right">
          <VueTooltip
            icon-color="grey"
            color="primary"
            text-class="white--text"
            :v-html="hoverText"
          />
        </span>
      </v-col>
      <v-col class="text-center pt-0" cols="12">
        <div class="font-size-h2 bold-text dark-grey-text">
          {{ count }}
        </div>
        <div>
          <span class="text-caption text-capitalize text-muted">
            {{ text }}
          </span>
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    count: {
      type: [Number, String],
      default: "",
    },
    hoverText: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    iconColor: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    imageHover: {
      type: String,
      default: "",
    },
  },
};
</script>

<style scoped></style>
