<template>
  <div>
    <v-row v-if="loading">
      <v-col cols="4" v-for="i in 3" :key="i">
        <v-skeleton-loader type="card"></v-skeleton-loader>
      </v-col>
    </v-row>
    <template v-else>
      <v-row v-if="!fromMainDashboard" align="center">
        <v-col class="text-center" :cols="getCols">
          <stats-card
            icon="mdi-cellphone-text"
            icon-color="light-blue lighten-2"
            @action="
              $router.push({
                name: 'app-releases',
                params: {
                  app_id: $route.params.app_id,
                },
              })
            "
            hover-text="Total active and archived releases in this app"
            text="Releases"
            :count="get(appDetail, 'stats.total_releases', '0') || 0"
          ></stats-card>
        </v-col>
        <v-col class="text-center" :cols="getCols">
          <stats-card
            icon="mdi-cellphone-arrow-down"
            @action="
              $router.push({
                name: 'app-installs',
                params: { app_id: $route.params.app_id },
              })
            "
            hover-text="Total installs and downloads in this app"
            icon-color="blue-grey lighten-2"
            text="Installs"
            :count="canManageApp ? totalInstalls || 0 : '-'"
          ></stats-card>
        </v-col>
        <v-col class="text-center" :cols="getCols">
          <stats-card
            icon="mdi-message"
            @action="
              $router.push({
                name: 'app-feedback',
                params: { app_id: $route.params.app_id },
              })
            "
            hover-text="Total feedback in this app"
            icon-color="green lighten-2"
            text="Feedback"
            :count="
             get(appDetail, 'stats.total_feedback', 0)
            "
          ></stats-card>
        </v-col>
        <!-- <v-col class="text-center" :cols="getCols">
          <stats-card
            icon="mdi-account-group"
            icon-color="brown lighten-2"
            @action="
              $router.push({
                name: 'app-members',
                params: { app_id: $route.params.app_id },
              })
            "
            hover-text="Total active team members in this app"
            text="Members"
            :count="get(appDetail, 'stats.total_members', '0')"
          ></stats-card>
        </v-col> -->
        <v-col v-if="appDetail && appDetail.storage" :cols="getCols">
          <stats-card
            :image="
              appDetail.storage.name
                ? get(appDetail, 'storage.icon', null) == 'testappio'
                  ? getStorageImg('testapp.io.svg')
                  : getStorageImg(get(appDetail, 'storage.icon', null))
                : getStorageImg('testapp.io.svg')
            "
            @action="
              $router.push({
                name: 'app-releases',
                params: {
                  app_id: $route.params.app_id,
                },
              })
            "
            hover-text="Storage for active releases in this app"
            text="Storage"
            :image-hover="get(appDetail, 'storage.name', '-')"
            :count="
            get(appDetail, 'storage.usage', null) == '-'
                ? '-'
                : formatBytes(get(appDetail, 'storage.usage', 0))
            "
          ></stats-card>
        </v-col>
      </v-row>
      <v-row v-else>
        <template v-if="getAppChartStats && getAppChartStats.meta">
          <v-col
            v-for="(item, index) in Object.keys(getAppChartStats.meta)"
            :key="index"
            class="text-center"
            cols="12"
            md="3"
          >
            <stats-card
              v-if="item"
              :icon="$options.statsColor[item].icon"
              :icon-color="$options.statsColor[item].color"
              :hover-text="$options.statsColor[item].info"
              :text="item"
              :count="
                !canManageApp && item === 'feedback'
                  ? '-'
                  : getAppChartStats.meta[item] || 0
              "
              @action="$router.push({ name: $options.statsColor[item].link })"
            ></stats-card>
          </v-col>
        </template>
      </v-row>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { isEmpty, get } from "@/core/services/helper.service";
import { formatBytes } from "@/core/services/helper.service";
import StatsCard from "@/view/components/Common/StatsCard";

export default {
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    fromMainDashboard: {
      type: Boolean,
      default: false,
    },
  },
  statsColor: {
    apps: {
      color: "deep-purple lighten-2",
      icon: "mdi-apps",
      link: "apps",
      info: "Your active apps",
    },
    releases: {
      color: "light-blue lighten-2",
      link: "apps",
      icon: "mdi-cellphone-text",
      info: "Releases created in your apps",
    },
    feedback: {
      color: "green lighten-2",
      link: "apps",
      icon: "mdi-message",
      info: "Feedback received in your apps",
    },
    members: {
      color: "brown lighten-2",
      icon: "mdi-account-group",
      link: "team-members",
      info: "Active members in this team",
    },
  },
  components: { StatsCard },
  data() {
    return {
      get,
      isEmpty,
      formatBytes,
    };
  },
  computed: {
    ...mapGetters({
      appDetail: "getAppInfo",
      getAppChartStats: "getAppChartStats",
    }),
    getCols() {
      if (
        this.$vuetify.breakpoint.mdAndDown &&
        this.$vuetify.breakpoint.smAndUp
      ) {
        return 6;
      } else if (this.$vuetify.breakpoint.mdAndUp) {
        return 3;
      } else if (this.$vuetify.breakpoint.smAndDown) {
        return 12;
      } else {
        return 6;
      }
    },
    totalInstalls() {
      const metaKeys =
        this.appDetail && this.appDetail.stats
          ? Object.keys(this.appDetail.stats)
          : [];
      if (
        !metaKeys.includes("total_installs") &&
        !metaKeys.includes("total_downloads")
      ) {
        return "0";
      } else {
        return (
          this.appDetail.stats.total_installs +
          this.appDetail.stats.total_downloads
        );
      }
    },
  },
};
</script>

<style scoped></style>
