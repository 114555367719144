<template>
  <v-row>
    <v-col class="pt-1" cols="6">
      <v-breadcrumbs :items="breadCrumbs" class="text-body-1 pa-2">
        <template slot="item" slot-scope="props">
          <span
            :class="
              props.item.disabled
                ? 'dark-grey-text'
                : 'indigo--text text--accent-2 pointer'
            "
            @click="$router.push(props.item.to)"
            v-text="props.item.text"
          ></span>
        </template>
        <v-icon slot="divider">mdi-chevron-right</v-icon>
      </v-breadcrumbs>
    </v-col>
    <v-col v-if="fromAppPage || fromTeamPage" cols="6">
      <div v-if="canManageApp && fromAppPage" style="float: right">
        <create-app-panel></create-app-panel>
        <!--        <div class="text-center mt-2">-->
        <!--          <span class="mr-2">Total: </span>-->
        <!--          <b-->
        <!--            class="mr-2"-->
        <!--            :class="-->
        <!--              getTeamPlanLimits.apps &&-->
        <!--              getTeamPlanLimits.apps < getTotalAppsCount-->
        <!--                ? 'error&#45;&#45;text'-->
        <!--                : ''-->
        <!--            "-->
        <!--            >{{ getTotalAppsCount }}/<span>{{-->
        <!--              getTeamPlanLimits.apps || "∞"-->
        <!--            }}</span></b-->
        <!--          >-->
        <!--          <vue-tooltip-->
        <!--            icon-color="primary"-->
        <!--            text="This is the limit for your total apps in this team. Check the plans page for more info."-->
        <!--          ></vue-tooltip>-->
        <!--        </div>-->
      </div>
      <div class="text-right" style="float: right" v-if="fromTeamPage">
        <v-btn
          v-if="canManageTeam && !getInactiveUserMessage && !getIsUserDisabled"
          @click="$emit('edit-team')"
          color="primary"
          depressed
          id="editTeamBtn"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-1 mx-1' : 'mx-3'"
          class="text-transform-none"
        >
          <v-icon
            small
            v-text="'mdi-file-document-edit'"
            left
            color="white"
          ></v-icon>
          Edit
        </v-btn>
        <v-tooltip
          v-if="!canManageApp || getInactiveUserMessage || getIsUserDisabled"
          color="primary"
          bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="mx-3 text-transform-none" v-on="on" depressed>
              <v-icon
                small
                v-text="'mdi-file-document-edit'"
                left
                color="grey"
              ></v-icon>
              Edit
            </v-btn>
          </template>
          <span>{{
            getInactiveUserMessage
              ? getInactiveUserMessage
              : "You don't have the permission to edit this team"
          }}</span>
        </v-tooltip>
        <v-btn
          @click="$emit('leave-team')"
          color="error"
          id="leaveTeamBtn"
          depressed
          class="text-transform-none"
        >
          <v-icon small color="white" left>exit_to_app</v-icon>
          Leave</v-btn
        >
      </div>
    </v-col>
    <v-col cols="12">
      <slot></slot>
    </v-col>
  </v-row>
</template>

<script>
import CreateAppPanel from "@/view/components/App/CreateAppPanel.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    CreateAppPanel,
  },
  props: {
    fromTeamPage: {
      type: Boolean,
      default: false,
    },
    breadCrumbs: {
      type: Array,
      default() {
        return [];
      },
    },
    fromAppPage: {
      type: Boolean,
      deafult: false,
    },
  },
  computed: {
    ...mapGetters({
      getTeamPlanLimits: "getTeamPlanLimits",
      getTotalAppsCount: "getTotalAppsCount",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
  },
};
</script>

<style lang="scss" scoped></style>
