<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-card height="100%" outlined>
        <v-card-title>
          <div class="dark-grey-text">
            <div class="bold-text text-dark">Releases</div>
            <div class="text-body-1 text-muted bold-text">
              Releases created for each month
            </div>
          </div>
          <v-spacer></v-spacer>
          <VueTooltip
            color="primary"
            custom
            :v-html="
              fromMainDashboard
                ? 'Releases created in all your apps in this team'
                : 'Releases created in this app'
            "
          >
            <template>
              <v-btn
                icon
                class="font-weight-bold title"
                color="primary"
                absolute
                top
                right
                style="z-index: 3"
              >
                ?
              </v-btn>
            </template>
          </VueTooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="loading">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </div>
          <template v-else>
            <line-charts
              v-if="
                getAppChartStats &&
                getAppChartStats.releases &&
                getAppChartStats.releases.length
              "
              :chart-data="releasesChartData"
            ></line-charts>
            <v-row v-else>
              <v-col cols="12">
                <no-data
                  first-text="No data"
                  second-text="Releases can be both Android & iOS"
                ></no-data>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card height="100%" outlined>
        <v-card-title>
          <div class="dark-grey-text">
            <div class="bold-text text-dark">Feedback</div>
            <div class="text-body-1 text-muted bold-text">
              Feedback received for each month
            </div>
          </div>
          <v-spacer></v-spacer>
          <VueTooltip
            color="primary"
            custom
            :v-html="
              fromMainDashboard
                ? 'Feedback received for all your apps in this team'
                : 'Feedback received for this app'
            "
          >
            <template>
              <v-btn
                icon
                class="font-weight-bold title"
                color="primary"
                absolute
                top
                right
                style="z-index: 3"
              >
                ?
              </v-btn>
            </template>
          </VueTooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="loading">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </div>
          <template v-else>
            <line-charts
              v-if="
                getAppChartStats &&
                getAppChartStats.feedback &&
                getAppChartStats.feedback.length
              "
              :chart-data="feedbackChartData"
            ></line-charts>
            <v-row v-else-if="!canManageApp">
              <v-col cols="12">
                <no-data
                  first-text="No feedback"
                  second-text="You don't have the permisison to view or manage the feedback"
                ></no-data>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12">
                <no-data
                  first-text="No data"
                  second-text="Start collecting feedback from the public install page and from our app"
                ></no-data>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card height="100%" outlined>
        <v-card-title>
          <v-icon left v-text="'mdi-police-badge-outline'"></v-icon>
          <div class="dark-grey-text">
            <div class="font-size-h3 bold-text">Top Developers</div>
            <div class="text-muted bold-text text-body-1">
              Developers with most releases
            </div>
          </div>
          <v-spacer></v-spacer>
          <VueTooltip
            color="primary"
            custom
            :v-html="
              fromMainDashboard
                ? 'Releases inside your apps created by the rockstars!'
                : 'Releases inside this app created by the rockstars!'
            "
          >
            <template>
              <v-btn
                icon
                class="font-weight-bold title"
                color="primary"
                absolute
                top
                right
                style="z-index: 3"
              >
                ?
              </v-btn>
            </template>
          </VueTooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="loading">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </div>
          <template v-else>
            <bar-charts
              v-if="
                getAppChartStats &&
                getAppChartStats.top_developers &&
                getAppChartStats.top_developers.length
              "
              display-legend
              :chart-data="topDevelopersData"
            ></bar-charts>
            <v-row v-else>
              <no-data
                :second-text="'Developers with most created releases'"
              ></no-data>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" md="6">
      <v-card height="100%" outlined>
        <v-card-title>
          <v-icon v-text="'mdi-chart'" class="mr-2"></v-icon>
          <div class="dark-grey-text">
            <div class="font-size-h3 bold-text">Installs</div>
            <div class="text-muted bold-text text-body-1">
              Release installs for each month
            </div>
          </div>
          <v-spacer></v-spacer>
          <VueTooltip
            color="primary"
            custom
            :v-html="
              (fromMainDashboard
                ? 'Release installs for all your apps in this team'
                : 'Release installs for all releases in this app') +
              '<br /><br />We are updating this info every minute.<br /><br />Those installs are mostly unique installs as per browser & device <br /><br />  Please note this feature is in BETA, please report if you think the data is incorrect. <br /><br />We welcome any feedback!'
            "
          >
            <template>
              <v-btn
                icon
                class="font-weight-bold title"
                color="primary"
                absolute
                top
                right
                style="z-index: 3"
              >
                ?
              </v-btn>
            </template>
          </VueTooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="loading">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </div>
          <template v-else>
            <line-charts
              v-if="
                getAppChartStats &&
                getAppChartStats.installs &&
                getAppChartStats.installs.length
              "
              :chart-data="installChartData"
            ></line-charts>
            <v-row v-else-if="!canManageApp">
              <v-col cols="12">
                <no-data
                  first-text="No installs or downloads"
                  second-text="You don't have the permisison to view the installs or downloads"
                ></no-data>
              </v-col>
            </v-row>
            <v-row v-else>
              <no-data
                second-text="Release installs from the public install page and from our app"
              ></no-data>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- <v-col cols="12" md="6">
      <v-card height="100%" outlined>
        <v-card-title>
          <v-icon left v-text="'mdi-police-badge-outline'"></v-icon>
          <div class="dark-grey-text">
            <div class="font-size-h3 bold-text">Top Installs</div>
            <div class="text-muted bold-text text-body-1">
              Releases with the most installs
            </div>
          </div>
          <v-spacer></v-spacer>
          <VueTooltip
            color="primary"
            custom
            :v-html="
              fromMainDashboard
                ? 'Total installs for the active and archived releases inside all active apps in this team'
                : 'Total installs for the active and archived releases'
            "
          >
            <template>
              <v-btn
                icon
                class="font-weight-bold title"
                color="primary"
                absolute
                top
                right
                style="z-index: 3"
              >
                ?
              </v-btn>
            </template>
          </VueTooltip>
        </v-card-title>
        <v-card-text class="pa-0">
          <div v-if="loading">
            <v-skeleton-loader type="card"></v-skeleton-loader>
          </div>
          <template v-else>
            <doughnut-charts
              v-if="
                getAppChartStats &&
                getAppChartStats.most_installed &&
                getAppChartStats.most_installed.length
              "
              display-legend
              :chart-data="topInstallChartData"
            ></doughnut-charts>
            <v-row v-else>
              <no-data
                :second-text="'You will be seeing here the most installed releases'"
              ></no-data>
            </v-row>
          </template>
        </v-card-text>
      </v-card>
    </v-col> -->
  </v-row>
</template>

<script>
import {
  GET_APP_CHART_STATS,
  GET_DASHBORD_APP_CHART_STATS,
} from "@/store/apps/app.module.js";
import LineCharts from "@/view/components/Common/Charts/LineCharts.vue";
import BarCharts from "@/view/components/Common/Charts/BarCharts.vue";
// import ProfileImage from "@/view/components/User/Image.vue";
import { mapGetters } from "vuex";
// import DoughnutCharts from "@/view/components/Common/Charts/DoughnutCharts.vue";

export default {
  components: { LineCharts, BarCharts },
  props: {
    fromMainDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      backgroundColor: [
        "#47ADFB",
        "#34BFA3",
        "#F4516C",
        "#FFB822",
        "#455A64",
        "#3366E6",
        "#43A047",
        "#FFEA00",
        "#716ACA",
      ],
      releasesChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Releases",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(79, 195, 247)",
            tension: 0.1,
          },
        ],
      },
      feedbackChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Feedback",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(129, 199, 132)",
            tension: 0.1,
          },
        ],
      },
      installChartData: {
        labels: [
          "Jan",
          "Feb",
          "Mar",
          "Apr",
          "May",
          "Jun",
          "Jul",
          "Aug",
          "Sep",
          "Oct",
          "Nov",
          "Dec",
        ],
        datasets: [
          {
            label: "Installs",
            data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            fill: false,
            borderColor: "rgb(144, 164, 174)",
            tension: 0.1,
          },
        ],
      },
      // topInstallChartData: {
      //   labels: [],
      //   datasets: [
      //     {
      //       label: "Installs",
      //       backgroundColor: [],
      //       data: [],
      //     },
      //   ],
      // },
      topDevelopersData: {
        labels: [],
        datasets: [
          {
            label: "Releases",
            backgroundColor: "rgb(79, 195, 247)",
            borderColor: "rgb(79, 195, 247)",
            barPercentage: 0.4,
            data: [],
          },
        ],
      },
    };
  },
  computed: {
    ...mapGetters({
      getAppChartStats: "getAppChartStats",
      getTeamId: "getTeamId",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
  },
  created() {
    if (!this.getInactiveUserMessage) {
      this.fetchAppChartStats();
    }
  },
  methods: {
    fetchAppChartStats() {
      if (this.loading) return;
      this.loading = true;
      const endpoint = this.fromMainDashboard
        ? GET_DASHBORD_APP_CHART_STATS
        : GET_APP_CHART_STATS;
      this.$store
        .dispatch(endpoint, this.$route.params.app_id)
        .then(async () => {
          if (this.getAppChartStats) {
            await this.setReleasesChartStats();
            await this.setFeedbackChartStats();
            await this.setInstallChartData();
            await this.setTopDeveloperData();
            // await this.setTopInstallChartData();
          }

          this.loading = false;
        })
        .catch((err) => {
          this.notifyErrorMessage({ message: err.message });
          this.loading = false;
        });
    },
    setReleasesChartStats() {
      this.getAppChartStats.releases.forEach((item) => {
        let month = new Date(item.to_date).getMonth();
        this.releasesChartData.labels.forEach((_, index) => {
          if (month === index) {
            this.releasesChartData.datasets[0].data[month] = item.count;
          }
        });
      });
    },
    setFeedbackChartStats() {
      if (this.getAppChartStats.feedback) {
        this.getAppChartStats.feedback.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.feedbackChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.feedbackChartData.datasets[0].data[month] = item.count;
            }
          });
        });
      }
    },
    setInstallChartData() {
      if (this.getAppChartStats.installs) {
        this.getAppChartStats.installs.forEach((item) => {
          let month = new Date(item.to_date).getMonth();
          this.installChartData.labels.forEach((_, index) => {
            if (month === index) {
              this.installChartData.datasets[0].data[month] = item.count;
            }
          });
        });
      }
    },
    // setTopInstallChartData() {
    //   this.getAppChartStats.most_installed.forEach((item) => {
    //     this.topInstallChartData.labels.push(
    //       `${item.app ? item.app.name || "" : ""} ${item.version} (${
    //         item.version_code
    //       })`
    //     );
    //     this.topInstallChartData.datasets[0].data.push(item.installs);
    //   });
    //   this.topInstallChartData.datasets[0].backgroundColor =
    //     this.backgroundColor;
    // },
    setTopDeveloperData() {
      this.getAppChartStats.top_developers.forEach((item) => {
        this.topDevelopersData.labels.push(item.name);
        this.topDevelopersData.datasets[0].data.push(item.total_releases);
      });
    },
  },
};
</script>

<style scoped></style>
