var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loading)?_c('v-row',_vm._l((3),function(i){return _c('v-col',{key:i,attrs:{"cols":"4"}},[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1)}),1):[(!_vm.fromMainDashboard)?_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":_vm.getCols}},[_c('stats-card',{attrs:{"icon":"mdi-cellphone-text","icon-color":"light-blue lighten-2","hover-text":"Total active and archived releases in this app","text":"Releases","count":_vm.get(_vm.appDetail, 'stats.total_releases', '0') || 0},on:{"action":function($event){return _vm.$router.push({
              name: 'app-releases',
              params: {
                app_id: _vm.$route.params.app_id,
              },
            })}}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":_vm.getCols}},[_c('stats-card',{attrs:{"icon":"mdi-cellphone-arrow-down","hover-text":"Total installs and downloads in this app","icon-color":"blue-grey lighten-2","text":"Installs","count":_vm.canManageApp ? _vm.totalInstalls || 0 : '-'},on:{"action":function($event){return _vm.$router.push({
              name: 'app-installs',
              params: { app_id: _vm.$route.params.app_id },
            })}}})],1),_c('v-col',{staticClass:"text-center",attrs:{"cols":_vm.getCols}},[_c('stats-card',{attrs:{"icon":"mdi-message","hover-text":"Total feedback in this app","icon-color":"green lighten-2","text":"Feedback","count":_vm.get(_vm.appDetail, 'stats.total_feedback', 0)},on:{"action":function($event){return _vm.$router.push({
              name: 'app-feedback',
              params: { app_id: _vm.$route.params.app_id },
            })}}})],1),(_vm.appDetail && _vm.appDetail.storage)?_c('v-col',{attrs:{"cols":_vm.getCols}},[_c('stats-card',{attrs:{"image":_vm.appDetail.storage.name
              ? _vm.get(_vm.appDetail, 'storage.icon', null) == 'testappio'
                ? _vm.getStorageImg('testapp.io.svg')
                : _vm.getStorageImg(_vm.get(_vm.appDetail, 'storage.icon', null))
              : _vm.getStorageImg('testapp.io.svg'),"hover-text":"Storage for active releases in this app","text":"Storage","image-hover":_vm.get(_vm.appDetail, 'storage.name', '-'),"count":_vm.get(_vm.appDetail, 'storage.usage', null) == '-'
              ? '-'
              : _vm.formatBytes(_vm.get(_vm.appDetail, 'storage.usage', 0))},on:{"action":function($event){return _vm.$router.push({
              name: 'app-releases',
              params: {
                app_id: _vm.$route.params.app_id,
              },
            })}}})],1):_vm._e()],1):_c('v-row',[(_vm.getAppChartStats && _vm.getAppChartStats.meta)?_vm._l((Object.keys(_vm.getAppChartStats.meta)),function(item,index){return _c('v-col',{key:index,staticClass:"text-center",attrs:{"cols":"12","md":"3"}},[(item)?_c('stats-card',{attrs:{"icon":_vm.$options.statsColor[item].icon,"icon-color":_vm.$options.statsColor[item].color,"hover-text":_vm.$options.statsColor[item].info,"text":item,"count":!_vm.canManageApp && item === 'feedback'
                ? '-'
                : _vm.getAppChartStats.meta[item] || 0},on:{"action":function($event){return _vm.$router.push({ name: _vm.$options.statsColor[item].link })}}}):_vm._e()],1)}):_vm._e()],2)]],2)}
var staticRenderFns = []

export { render, staticRenderFns }