<template>
  <img
    :src="image && appId ? getAppImage(image, appId) : defaultImg"
    :height="size"
    :class="className"
    :alt="alt || 'app'"
    @error="replaceDefaultAppImage"
  />
</template>

<script>
export default {
  name: "app-image",
  props: {
    defaultImg: {
      type: [String],
      default: "/media/defaults/icon-50.png",
    },
    image: [String, Boolean, Object],
    appId: [String, Number],
    className: [String],
    alt: [String],
    size: {
      type: [String, Number],
      default: 50,
    },
  },
};
</script>
