<template>
  <page-wrapper :bread-crumbs="breadCrumbs">
    <Modal v-model="phoneUserWarning" hide-close>
      <template #message>
        <div class="my-n3">
          <v-card-title class="font-weight-bold"> Attention </v-card-title>
          <v-divider />
          <v-card-text class="message">
            Portal is best managed in your desktop or laptop browser.
            <br />
            <br />

            From our app you can install the releases that was created in this
            Portal.
            <br />
            <br />
            If you don't have our app yet,
            <a href="/start?from=attention-popup">click here to install.</a>
          </v-card-text>

          <div class="d-flex justify-center text-center mt-3 px-2">
            <v-checkbox v-model="checkbox">
              <template slot="label">
                <div class="text-body-1">Don't show this message again</div>
              </template>
            </v-checkbox>
          </div>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="main primary" @click="phoneUserWarning = false"
              >Ok, got it!</v-btn
            >
          </v-card-actions>
        </div>
      </template>
    </Modal>
    <!-- dashboard page -->
    <v-row v-if="getInactiveUserMessage">
      <v-col cols="12">
        <v-alert outlined color="error">
          {{ getInactiveUserMessage }}
        </v-alert>
      </v-col>
    </v-row>
    <v-row v-if="!getInactiveUserMessage && !dashboardLoader">
      <v-col class="mt-3" cols="12">
        <app-stats from-main-dashboard></app-stats>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <app-charts from-main-dashboard></app-charts>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="100%" outlined>
          <v-card-title>
            <div class="card-label bold-text text-dark font-16">
              Recent Apps
              <div class="text-muted bold-text font-size-sm">
                {{ total_apps == 1 ? `1 app` : `${total_apps} apps` }}
              </div>
            </div>
            <v-spacer></v-spacer>
            <div
              v-if="canManageApp && !getInactiveUserMessage"
              class="card-toolbar"
            >
              <CreateAppPanel id="createAppButton" />
            </div>
          </v-card-title>
          <v-card-text class="">
            <div class="smooth-table card-body pa-0">
              <v-row height="50" v-if="dashboardLoader">
                <v-col cols="12" v-for="i in 3" :key="i">
                  <v-skeleton-loader
                    class="transparent mx-n4"
                    :loading="true"
                    v-bind="attrs"
                    type="list-item-avatar-two-line"
                  />
                </v-col>
              </v-row>
              <!-- <ContentLoader v-if="dashboardLoader" /> -->
              <div
                v-else-if="!canManageApp && !apps.length"
                class="no-data-found"
              >
                <no-data
                  icon="/media/placeholders/no_apps.svg"
                  first-text="No apps"
                  second-text="Apps will be holding releases to be installed from our mobile app"
                ></no-data>
              </div>
              <div v-else-if="noApps" class="no-data-found">
                <no-data
                  icon="/media/placeholders/no_apps.svg"
                  first-text="No apps"
                  second-text="Click on create app button to get started"
                ></no-data>
              </div>
              <div v-else>
                <template v-for="(item, i) in apps">
                  <v-row
                    no-gutters
                    align="center"
                    justify="center"
                    style="cursor: pointer"
                    class="smooth-rows d-flex align-center"
                    v-bind:key="i"
                    @click="navigateToApp('app-dashboard', item.id)"
                  >
                    <v-col
                      :class="{ 'ml-5': $vuetify.breakpoint.smAndDown }"
                      cols="12"
                      md="5"
                      class="d-flex my-2 mr-5"
                    >
                      <AppImage
                        :appId="item.id"
                        :image="item.image"
                        className="img-fluid align-self-center"
                        :alt="item.name"
                      />
                      <div
                        class="
                          ml-4
                          justify-center
                          d-flex
                          flex-column
                          bold-text
                          flex-grow-1
                          mr-4
                          release-name
                        "
                      >
                        <a
                          class="text-dark text-hover-primary font-size-lg mb-1"
                        >
                          {{ item.name || "" }}</a
                        >
                        <span class="text-muted">
                          <vue-hoverable-date
                            :date="item.created_at"
                          ></vue-hoverable-date>
                        </span>
                      </div>
                    </v-col>
                    <v-col
                      class="mt-1"
                      v-if="$vuetify.breakpoint.smAndDown"
                      cols="12"
                    >
                      <v-divider></v-divider>
                    </v-col>
                    <v-col class="d-flex">
                      <span class="smooth-chip">
                        <PlatformIcon
                          android
                          class="px-2"
                          size="20"
                          :color="
                            item.platforms.indexOf('android') == -1
                              ? 'grey'
                              : 'primary'
                          "
                        />
                        <PlatformIcon
                          apple
                          class="px-2"
                          size="20"
                          :color="
                            item.platforms.indexOf('ios') == -1
                              ? 'grey'
                              : 'primary'
                          "
                        />
                      </span>
                    </v-col>

                    <v-col
                      class="
                        d-flex
                        flex-grow-1
                        text-muted
                        justify-center
                        font-weight-500
                        version-rate
                      "
                    >
                      <span class="mr-2">
                        {{
                          get(item, "info.total_releases", 0) == 1
                            ? `1 release`
                            : `${get(item, "info.total_releases", 0)} releases`
                        }}
                      </span>
                    </v-col>
                    <a
                      :class="{ mobile: $vuetify.breakpoint.smAndDown }"
                      class="smooth-btn d-flex justify-center align-center"
                      @click="navigateToApp('app-dashboard', item.id)"
                    >
                      <i class="arrow-right"></i>
                    </a>
                  </v-row>
                </template>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%" outlined>
          <v-card-title>
            <div>
              <div class="card-label bold-text text-dark">Recent Releases</div>
              <div class="text-muted bold-text font-size-sm">
                {{
                  total_releases == 1
                    ? `1 release`
                    : `${total_releases} releases`
                }}
              </div>
            </div>
            <v-spacer />
            <div
              v-if="canManageRelease && !getInactiveUserMessage"
              class="card-toolbar"
            >
              <NewReleasePanel />
            </div>
          </v-card-title>
          <v-card-text>
            <v-row height="50" v-if="dashboardLoader">
              <v-col cols="12" v-for="i in 3" :key="i">
                <v-skeleton-loader
                  class="transparent mx-n4"
                  :loading="true"
                  v-bind="attrs"
                  type="list-item-avatar-two-line"
                />
              </v-col>
            </v-row>
            <div v-else-if="!noReleases" class="smooth-table">
              <template v-for="(item, i) in releases.slice(0, 5)">
                <v-row
                  no-gutters
                  class="smooth-rows d-flex align-center"
                  style="cursor: pointer"
                  v-bind:key="i"
                  @click="
                    navigateToApp('app-releases', get(item, 'app.id', ''))
                  "
                >
                  <v-col
                    justify="center"
                    cols="12"
                    md="5"
                    class="my-2 d-flex mr-5"
                  >
                    <AppImage
                      :appId="item.id"
                      :image="item.image"
                      className="img-fluid align-self-center"
                      :alt="item.name"
                    />

                    <!-- <img
                          height="50"
                          class="img-fluid align-self-center"
                          :src="item.image"
                          @error="replaceDefaultReleaseImage"
                        /> -->
                    <div
                      class="
                        ml-4
                        mr-4
                        d-flex
                        flex-column flex-grow-1
                        bold-text
                        release-name
                      "
                    >
                      <h4
                        class="text-dark darkGrey--text font-size-lg mb-1"
                        style="cursor: pointer"
                      >
                        {{ get(item, "app.name", "") }}
                      </h4>
                      <span class="text-muted">
                        <vue-hoverable-date
                          :date="item.created_at"
                        ></vue-hoverable-date>
                      </span>
                      <!-- <span class="text-muted">
                            {{
                              get(item, "user.name", "")
                                ? `By ${item.user.name}`
                                : ""
                            }}
                          </span> -->
                    </div>
                  </v-col>
                  <v-col
                    class="mt-1"
                    v-if="$vuetify.breakpoint.smAndDown"
                    cols="12"
                  >
                    <v-divider></v-divider>
                  </v-col>

                  <v-col
                    class="
                      d-flex
                      flex-column flex-grow-1
                      version-rate
                      smooth-chip
                    "
                  >
                    <PlatformIcon
                      android
                      size="20"
                      color="primary"
                      class="platforms"
                      v-if="item.platform == 'android'"
                    />
                    <PlatformIcon
                      apple
                      size="20"
                      color="primary"
                      class="platforms"
                      v-if="item.platform == 'ios'"
                    />
                  </v-col>
                  <v-col
                    class="
                      d-flex
                      flex-column flex-grow-1
                      text-muted
                      font-weight-500
                      version-rate
                      text-center
                    "
                  >
                    <MaxText
                      :text="item.version ? `v${item.version}` : '-'"
                      max="16"
                    />
                  </v-col>
                  <a
                    :class="{ mobile: $vuetify.breakpoint.smAndDown }"
                    class="smooth-btn d-flex justify-center align-center"
                    @click="
                      navigateToApp('app-releases', get(item, 'app.id', ''))
                    "
                  >
                    <i class="arrow-right"></i>
                  </a>
                </v-row>
              </template>
            </div>
            <div v-else class="no-data-found">
              <no-data
                icon="/media/placeholders/no_releases.svg"
                first-text="No releases"
                second-text="Upload Android (APK) & iOS (IPA) to your apps"
              ></no-data>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-card height="100%" outlined>
          <v-card-title>
            <div>
              <div class="bold-text text-dark">Recent Team Members</div>
              <div class="text-muted bold-text font-size-sm">
                {{
                  total_team_members == 1
                    ? `1 member`
                    : `${total_team_members} members`
                }}
              </div>
            </div>
            <v-spacer />
            <div
              v-if="canManageInvite && !getInactiveUserMessage"
              class="card-toolbar"
            >
              <InviteMembersPanel id="createInviteBtn" />
            </div>
          </v-card-title>
          <v-card-text>
            <v-row height="50" v-if="dashboardLoader">
              <v-col cols="12" v-for="i in 3" :key="i">
                <v-skeleton-loader
                  class="transparent"
                  :loading="true"
                  v-bind="attrs"
                  type="list-item-avatar-two-line"
                />
              </v-col>
            </v-row>

            <div v-else-if="team_members.length">
              <template>
                <v-row
                  no-gutters
                  class="smooth-rows py-3 d-flex align-center"
                  v-for="(item, i) in team_members"
                  v-bind:key="i"
                  style="cursor: pointer"
                  @click="goToManageTeamMembers()"
                >
                  <v-col cols="12" md="5" class="d-flex align-center mr-5">
                    <v-avatar size="50">
                      <ProfileImage
                        :image="item.image"
                        className="img-fluid align-self-center rounded-circle"
                        :alt="item.name"
                      />
                    </v-avatar>
                    <div
                      class="
                        ml-4
                        d-flex
                        flex-column flex-grow-1
                        bold-text
                        release-name
                      "
                    >
                      <a class="text-dark text-hover-primary font-size-lg mb-1">
                        <MaxText :text="item.name" max="20" />
                      </a>
                      <span class="text-muted">
                        <vue-hoverable-date :date="item.created_at" />
                      </span>
                    </div>
                  </v-col>
                  <v-col
                    class="mt-1"
                    v-if="$vuetify.breakpoint.smAndDown"
                    cols="12"
                  >
                    <v-divider></v-divider>
                  </v-col>

                  <v-col
                    class="
                      d-flex
                      flex-column flex-grow-1
                      text-muted
                      bold-text
                      version-rate1
                      smooth-chip
                    "
                  >
                    <MaxText :text="get(item, 'team.name', '')" max="10" />
                  </v-col>
                  <v-col
                    class="
                      d-flex
                      flex-column flex-grow-1
                      text-muted
                      bold-text
                      version-rate2
                      text-center
                    "
                  >
                    {{ get(item, "team.role", "") }}
                  </v-col>
                  <a
                    :class="{ mobile: $vuetify.breakpoint.smAndDown }"
                    class="smooth-btn d-flex justify-center align-center"
                    @click="($event) => goToManageTeamMembers()"
                  >
                    <i class="arrow-right"></i>
                  </a>
                </v-row>
              </template>
            </div>
            <div v-else class="no-data-found">
              <no-data
                first-text="No team members to show"
                second-text="Members can have different roles such as developer, tester and custom"
              ></no-data>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6">
        <v-card height="100%" outlined>
          <v-card-title>
            <div>
              <div class="card-label bold-text text-dark">Recent Feedback</div>
              <div
                class="text-muted bold-text font-size-sm"
                v-if="canManageApp"
              >
                {{ total_feedback || feedback ? feedback.length : 0 }} feedback
              </div>
            </div>
            <v-spacer />
          </v-card-title>
          <v-card-text>
            <v-row height="50" v-if="dashboardLoader">
              <v-col cols="12" v-for="i in 3" :key="i">
                <v-skeleton-loader
                  class="transparent mx-n4"
                  :loading="true"
                  v-bind="attrs"
                  type="list-item-avatar-two-line"
                />
              </v-col>
            </v-row>
            <div v-else-if="feedback && feedback.length">
              <v-row
                no-gutters
                v-for="(item, i) in feedback"
                class="smooth-rows py-3 d-flex align-center"
                style="cursor: pointer"
                v-bind:key="i"
                @click="navigateToApp('app-feedback', get(item, 'app.id', ''))"
              >
                <v-col cols="12" md="5" class="d-flex mr-5">
                  <div
                    class="
                      ml-4
                      d-flex
                      flex-column flex-grow-1
                      bold-text
                      release-name
                    "
                  >
                    <a class="text-dark text-hover-primary font-size-lg mb-1">
                      <MaxText :text="get(item, 'user.name', '-')" max="20" />
                    </a>
                    <!-- <span class="text-muted">
                      <MaxText :text="get(item, 'user.email', '-')" max="18" />
                    </span> -->
                    <span class="text-muted">
                      <vue-hoverable-date :date="item.created_at" />
                    </span>
                  </div>
                </v-col>
                <v-col
                  class="mt-1"
                  v-if="$vuetify.breakpoint.smAndDown"
                  cols="12"
                >
                  <v-divider></v-divider>
                </v-col>

                <v-col
                  class="
                    d-flex
                    flex-column flex-grow-1
                    text-muted
                    bold-text
                    version-rate1
                    smooth-chip
                  "
                >
                  <MaxText :text="get(item, 'app.name', '-')" max="18" />
                </v-col>
                <v-col
                  class="
                    d-flex
                    flex-column flex-grow-1
                    text-muted
                    bold-text
                    version-rate2
                    text-center
                  "
                >
                  <country-city :detail="item"></country-city>
                </v-col>
                <a
                  :class="{ mobile: $vuetify.breakpoint.smAndDown }"
                  class="smooth-btn d-flex justify-center align-center"
                  @click="
                    navigateToApp('app-feedback', get(item, 'app.id', ''))
                  "
                >
                  <i class="arrow-right"></i>
                </a>
              </v-row>
            </div>
            <v-row v-else-if="!canManageApp">
              <v-col cols="12">
                <no-data
                  first-text="No feedback"
                  second-text="You don't have the permisison to view or manage the feedback"
                ></no-data>
              </v-col>
            </v-row>
            <div v-else class="no-data-found">
              <no-data
                first-text="No feedback"
                second-text="Start collecting feedback from the public install page and from our app"
              ></no-data>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </page-wrapper>
</template>

<script>
import { isEmpty, get } from "@/core/services/helper.service";
import { mapGetters, mapMutations, mapState } from "vuex";
import { DASHBOARD } from "@/store/users/dashboard.module";
import CreateAppPanel from "@/view/components/App/CreateAppPanel.vue";
import NewReleasePanel from "@/view/components/Releases/NewReleasePanel.vue";
import ProfileImage from "@/view/components/User/Image.vue";
import AppImage from "@/view/components/App/Image.vue";
import InviteMembersPanel from "@/view/components/Invites/InviteMembersPanel.vue";
import AppCharts from "@/view/components/App/Dashboard/AppCharts.vue";
import AppStats from "@/view/components/App/Dashboard/AppStats.vue";
import PageWrapper from "@/view/components/Common/PageWrapper.vue";
import CountryCity from "@/view/components/Common/CountryCity";

export default {
  name: "dashboard",
  data() {
    return {
      get,
      attrs: {},
      breadCrumbs: [
        { text: "Dashboard", disabled: false, to: { name: "dashboard" } },
      ],
      phoneUserWarning: false,
      checkbox: false,
    };
  },
  components: {
    AppStats,
    AppCharts,
    CreateAppPanel,
    NewReleasePanel,
    AppImage,
    ProfileImage,
    InviteMembersPanel,
    PageWrapper,
    CountryCity,
  },
  metaInfo: {
    title: "TestApp.io - Portal",
    link: [{ rel: "canonical", href: "https://portal.testapp.io/" }],
    meta: [
      {
        name: "og:description",
        content:
          "A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!",
      },
      {
        name: "twitter:description",
        content:
          "A platform where developers can easily share their app Android (APK) &amp; iOS (IPA) with their friends, colleagues, testers,... to get their instant feedback!",
      },
      // {
      //   name: "robots",
      //   content: "noindex,nofollow"
      // }
    ],
  },
  watch: {
    checkbox(val) {
      localStorage.setItem(
        "popup",
        JSON.stringify({
          ...JSON.parse(localStorage.getItem("popup")),
          phoneUserWarning: val.toString(),
        })
      );
    },
  },
  computed: {
    ...mapState({
      usage: (state) => get(state, "dashboard.usage", ""),
      apps: (state) => get(state, "dashboard.apps", ""),
      releases: (state) => get(state, "dashboard.releases", ""),
      feedback: (state) => get(state, "dashboard.feedback", ""),
      team_members: (state) => get(state, "dashboard.team_members", ""),
      total_apps: (state) => get(state, "dashboard.total_apps", 0),
      total_releases: (state) => get(state, "dashboard.total_releases", 0),
      total_feedback: (state) => get(state, "dashboard.total_feedback", 0),
      total_team_members: (state) =>
        get(state, "dashboard.total_team_members", 0),
      constants: (state) => state.util.constants,
      currentPlanUsage: (state) => state.dashboard.currentPlanUsage,
      dashboardLoader: (state) => state.dashboard.dashboardLoader,
    }),
    ...mapGetters({
      userPermissions: "userPermissions",
      getTeamId: "getTeamId",
      getInactiveUserMessage: "getInactiveUserMessage",
    }),
    noApps() {
      return isEmpty(this.apps) ? true : false;
    },
    noReleases() {
      return isEmpty(this.releases) ? true : false;
    },
  },
  created() {},
  mounted() {
    this.setAuthMessage({ message: "", type: "" });
    if (!this.getInactiveUserMessage) {
      this.$store.dispatch(DASHBOARD).catch((err) => {
        this.notifyErrorMessage({ message: err.message });
      });
    }
    const popup = JSON.parse(localStorage.getItem("popup"));
    if (this.getMobileOperatingSystem !== "unknown") {
      if (popup && popup.phoneUserWarning) {
        popup.phoneUserWarning === "true"
          ? (this.phoneUserWarning = false)
          : (this.phoneUserWarning = true);
      } else {
        this.phoneUserWarning = true;
      }
    }
  },
  methods: {
    ...mapMutations({
      setAuthMessage: "setAuthMessage",
    }),
    navigateToApp(name, id) {
      this.$router
        .push({
          name: name,
          params: {
            app_id: id,
          },
        })
        .catch(() => {});
    },
    goToManageTeamMembers() {
      this.$router.push({ name: "team-members" }).catch(() => {});
    },
    goToCreateApp() {
      this.$router.push({ name: "createapp" }).catch(() => {});
    },
    replaceDefaultReleaseImage(e) {
      e.target.src = "/media/defaults/icon-50.png";
    },
  },
};
</script>

<style lang="scss" scoped>
.text-muted {
  font-weight: 600;
}

.font-size-lg,
.text-muted {
  font-size: 13px !important;
}

.font-16 {
  font-size: 16px !important;
}

.platforms {
  font-size: 16px !important;
}
</style>
