var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"height":"100%","outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"dark-grey-text"},[_c('div',{staticClass:"bold-text text-dark"},[_vm._v("Releases")]),_c('div',{staticClass:"text-body-1 text-muted bold-text"},[_vm._v(" Releases created for each month ")])]),_c('v-spacer'),_c('VueTooltip',{attrs:{"color":"primary","custom":"","v-html":_vm.fromMainDashboard
              ? 'Releases created in all your apps in this team'
              : 'Releases created in this app'}},[[_c('v-btn',{staticClass:"font-weight-bold title",staticStyle:{"z-index":"3"},attrs:{"icon":"","color":"primary","absolute":"","top":"","right":""}},[_vm._v(" ? ")])]],2)],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1):[(
              _vm.getAppChartStats &&
              _vm.getAppChartStats.releases &&
              _vm.getAppChartStats.releases.length
            )?_c('line-charts',{attrs:{"chart-data":_vm.releasesChartData}}):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('no-data',{attrs:{"first-text":"No data","second-text":"Releases can be both Android & iOS"}})],1)],1)]],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"height":"100%","outlined":""}},[_c('v-card-title',[_c('div',{staticClass:"dark-grey-text"},[_c('div',{staticClass:"bold-text text-dark"},[_vm._v("Feedback")]),_c('div',{staticClass:"text-body-1 text-muted bold-text"},[_vm._v(" Feedback received for each month ")])]),_c('v-spacer'),_c('VueTooltip',{attrs:{"color":"primary","custom":"","v-html":_vm.fromMainDashboard
              ? 'Feedback received for all your apps in this team'
              : 'Feedback received for this app'}},[[_c('v-btn',{staticClass:"font-weight-bold title",staticStyle:{"z-index":"3"},attrs:{"icon":"","color":"primary","absolute":"","top":"","right":""}},[_vm._v(" ? ")])]],2)],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1):[(
              _vm.getAppChartStats &&
              _vm.getAppChartStats.feedback &&
              _vm.getAppChartStats.feedback.length
            )?_c('line-charts',{attrs:{"chart-data":_vm.feedbackChartData}}):(!_vm.canManageApp)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('no-data',{attrs:{"first-text":"No feedback","second-text":"You don't have the permisison to view or manage the feedback"}})],1)],1):_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('no-data',{attrs:{"first-text":"No data","second-text":"Start collecting feedback from the public install page and from our app"}})],1)],1)]],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"height":"100%","outlined":""}},[_c('v-card-title',[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-police-badge-outline')}}),_c('div',{staticClass:"dark-grey-text"},[_c('div',{staticClass:"font-size-h3 bold-text"},[_vm._v("Top Developers")]),_c('div',{staticClass:"text-muted bold-text text-body-1"},[_vm._v(" Developers with most releases ")])]),_c('v-spacer'),_c('VueTooltip',{attrs:{"color":"primary","custom":"","v-html":_vm.fromMainDashboard
              ? 'Releases inside your apps created by the rockstars!'
              : 'Releases inside this app created by the rockstars!'}},[[_c('v-btn',{staticClass:"font-weight-bold title",staticStyle:{"z-index":"3"},attrs:{"icon":"","color":"primary","absolute":"","top":"","right":""}},[_vm._v(" ? ")])]],2)],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1):[(
              _vm.getAppChartStats &&
              _vm.getAppChartStats.top_developers &&
              _vm.getAppChartStats.top_developers.length
            )?_c('bar-charts',{attrs:{"display-legend":"","chart-data":_vm.topDevelopersData}}):_c('v-row',[_c('no-data',{attrs:{"second-text":'Developers with most created releases'}})],1)]],2)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-card',{attrs:{"height":"100%","outlined":""}},[_c('v-card-title',[_c('v-icon',{staticClass:"mr-2",domProps:{"textContent":_vm._s('mdi-chart')}}),_c('div',{staticClass:"dark-grey-text"},[_c('div',{staticClass:"font-size-h3 bold-text"},[_vm._v("Installs")]),_c('div',{staticClass:"text-muted bold-text text-body-1"},[_vm._v(" Release installs for each month ")])]),_c('v-spacer'),_c('VueTooltip',{attrs:{"color":"primary","custom":"","v-html":(_vm.fromMainDashboard
              ? 'Release installs for all your apps in this team'
              : 'Release installs for all releases in this app') +
            '<br /><br />We are updating this info every minute.<br /><br />Those installs are mostly unique installs as per browser & device <br /><br />  Please note this feature is in BETA, please report if you think the data is incorrect. <br /><br />We welcome any feedback!'}},[[_c('v-btn',{staticClass:"font-weight-bold title",staticStyle:{"z-index":"3"},attrs:{"icon":"","color":"primary","absolute":"","top":"","right":""}},[_vm._v(" ? ")])]],2)],1),_c('v-card-text',{staticClass:"pa-0"},[(_vm.loading)?_c('div',[_c('v-skeleton-loader',{attrs:{"type":"card"}})],1):[(
              _vm.getAppChartStats &&
              _vm.getAppChartStats.installs &&
              _vm.getAppChartStats.installs.length
            )?_c('line-charts',{attrs:{"chart-data":_vm.installChartData}}):(!_vm.canManageApp)?_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('no-data',{attrs:{"first-text":"No installs or downloads","second-text":"You don't have the permisison to view the installs or downloads"}})],1)],1):_c('v-row',[_c('no-data',{attrs:{"second-text":"Release installs from the public install page and from our app"}})],1)]],2)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }