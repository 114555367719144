<template>
  <div class="topbar-item quick_create_app">
    <v-btn
      id="create-app-card-button"
      class="btn btn-start"
      @click="openCreateApp"
      v-if="smallBtn"
    >
      Let's start
    </v-btn>
    <v-btn v-else color="primary" class="main" @click="openCreateApp">
      <v-icon left>stay_current_portrait</v-icon>
      {{ text }}
    </v-btn>
    <SidePanel v-model="drawer" :loading="busy">
      <div>
        <v-card-title class="display-1 justify-center mb-8 mt-n3">
          Create app
        </v-card-title>
        <div role="alert" class="alert fade alert-danger">
          <div class="alert-text text-left caption">{{ error }}</div>
        </div>
        <create-app-step
          from-create-panel
          @close="drawer = false"
        ></create-app-step>
      </div>
    </SidePanel>
    <Modal
      v-model="openStartTrialPopup"
      @close="openStartTrialPopup = false"
      width="550"
    >
      <template #message>
        <start-trial-modal
          v-if="openStartTrialPopup"
          :message="'Your team has reached the maximum number of app projects'"
          from-apps-page
          :tracker-event="trackerEvent"
          @open-subscribe="openSubscriptionModal = true"
          @close="openStartTrialPopup = false"
        ></start-trial-modal>
      </template>
    </Modal>
    <Modal v-model="showUpgradeInfo" @close="showUpgradeInfo = false">
      <template #message>
        <upgrade-plan
          title="App projects"
          from-upgrade-component
          from-app-page
          info-text="to add more apps to your team and much more"
          @close="showUpgradeInfo = false"
        >
          <template>
            <div>
              <br />
              Your team has reached the maximum number of app projects.
              <br />
              <!-- {{ getTeamPlanLimits.apps }} apps -->
              <!-- This is on upgrade  -->
              <br /><span v-if="!IsActiveTrial">
                Upgrade today to take the advantage of our discount to add more
                apps and much more!
              </span>
              <!-- This is on free trial -->
              <span v-if="IsActiveTrial">
                Give it a try with your free trial to add more apps and much
                more!
              </span>
            </div>
          </template>
        </upgrade-plan>
      </template>
    </Modal>
  </div>
</template>

<script>
import CreateAppStep from "@/view/components/App/CreateAppStep";
import { mapGetters } from "vuex";
import { isEmpty } from "@/core/services/helper.service";
import UpgradePlan from "@/view/components/Team/UpgradePlan.vue";
import StartTrialModal from "@/view/components/Common/StartTrialModal";

export default {
  name: "create-app-panel",
  props: {
    smallBtn: {
      type: Boolean,
      default: false,
    },
    text: {
      type: String,
      default: "Create App",
    },
  },
  components: { CreateAppStep, UpgradePlan, StartTrialModal },
  computed: {
    ...mapGetters({
      getTeamPlanLimits: "getTeamPlanLimits",
      getTotalAppsCount: "getTotalAppsCount",
      getTeamTrial: "getTeamTrial",
    }),
    IsActiveTrial() {
      return this.getTeamTrial && !this.getTeamTrial.status;
    },
    isAppsLimitReached() {
      if (this.getTeamPlanLimits && !this.getTeamPlanLimits.apps) {
        return false;
      } else {
        return (
          this.getTeamPlanLimits &&
          this.getTeamPlanLimits.apps &&
          this.getTotalAppsCount >= this.getTeamPlanLimits.apps
        );
      }
    },
  },
  data() {
    return {
      drawer: false,
      error: null,
      showUpgradeInfo: false,
      trackerEvent: {},
      openStartTrialPopup: false,
      busy: false,
    };
  },
  created() {
    this.trackerEvent = {
      event: "apps",
      current: this.getTeamPlanLimits.apps,
      requested: 1,
    };
  },
  methods: {
    openCreateApp() {
      if (this.isAppsLimitReached) {
        if (isEmpty(this.getTeamTrial)) {
          this.openStartTrialPopup = true;
        } else {
          this.showUpgradeInfo = true;
        }
        window.analytics.track("App projects limit popup");
      } else {
        this.drawer = !this.drawer;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#quick_create_app {
  overflow: hidden;
}
.quick_create_app {
  .offcanvas {
    right: 0;
    &.offcanvas-right {
      right: -900px;
      &.offcanvas-on {
        right: 0;
      }
    }
  }
}
</style>
